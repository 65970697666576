.inhouse {
  border-top: 1px dashed rgba(0, 0, 0, 0.6) !important;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.6) !important;
  border-right: 1px dashed rgba(0, 0, 0, 0.6) !important;
  border-left: 1px dashed rgba(0, 0, 0, 0.6) !important;
}

.scheduler {
  margin-bottom: 0 !important;
  margin-top: 0.5em !important;
}

.scheduler .resource-table .header3-text,
.scheduler .resource-table .header2-text {
  font-weight: bold;
  font-size: 0.7rem;
}

.scheduler-view .header3-text {
  font-size: 0.6rem;
}

@media only screen and (min-width: 300px) {
  .scheduler .resource-table .header3-text,
  .scheduler .resource-table .header2-text {
    font-size: 0.8rem;
  }
  .scheduler-view .header3-text {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 600px) {
  .scheduler .resource-table .header3-text,
  .scheduler .resource-table .header2-text {
    font-size: 0.7rem;
  }
  .scheduler-view .header3-text {
    font-size: 0.5rem;
  }
}

@media only screen and (min-width: 1200px) {
  .scheduler .resource-table .header3-text,
  .scheduler .resource-table .header2-text {
    font-size: 0.9rem;
  }
  .scheduler-view .header3-text {
    font-size: 0.7rem;
  }
  .scheduler-view div:nth-child(2) {
    overflow-x: hidden !important; 
    max-height: fit-content;
  }
}

@media only screen and (min-width: 1500px) {
  .scheduler .resource-table .header3-text,
  .scheduler .resource-table .header2-text {
    font-size: 1rem;
  }
  .scheduler-view .header3-text {
    font-size: 0.8rem;
  }
}

.event-item > i {
  margin-left: 0.3rem;
}
