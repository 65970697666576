/*******************************
         Site Overrides
*******************************/

.ui.dimmer .ui.modal .ui.loader:before {
  border-color: rgba(0,0,0,.1);
}
          
.ui.dimmer .ui.modal .ui.loader:after {
  border-color: #767676 transparent transparent;
}
