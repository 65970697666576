/*******************************
         Site Overrides
*******************************/

.ui.button {
  box-shadow: none;
  margin: 0;
}

.ui.button.white {
  background: @white;
  border: 1px solid @lightGrey;

  &:hover {
    background: darken(@white, 5);
  }

  &:active {
    background: darken(@white, 7);
  }
}
